import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { accountReducer, AccountState } from "../account/account.reducer";
import { LOGOUT } from "../actions/app.actions";
import { settingsReducer, SettingsState } from '../settings/settings.reducer';


export interface AppState {
  account: AccountState;
  settings: SettingsState;
}

export const reducers: ActionReducerMap<AppState> = {
  account: accountReducer,
  settings: settingsReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logout] : [logout];

// @todo This is a little hack to clear the entire state via a meta reducer, not essential however cleaner if can
// find another way to do this.
function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    if(action.type === LOGOUT){
      // Clear entire state.
      state = {};
    }
    return reducer(state, action);
  };
}
