import { createAction, props } from "@ngrx/store";
import { SiteConfigDTO } from "@shared/models/SiteConfigDTO";

export const load = createAction(
  '[Settings] Load',
  props<{ settings: SiteConfigDTO }>()
);

export const update = createAction(
  '[Settings] Update',
  props<{ settings: SiteConfigDTO }>()
);
