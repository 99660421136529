import { Injectable } from '@angular/core';
import { User } from '@shared/models/User';
import { Store } from "@ngrx/store";
import { Observable, pairwise, take } from 'rxjs';
import * as AccountActions from '../../store/account/account.actions';
import { getUser } from "../../store/account/account.selectors";
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import * as Sentry from "@sentry/angular";
import { ApiService } from "@gatho/services/api/api.service";

@Injectable({
  providedIn: 'root'
})

export class UserService {

  // User NGRX 'store'
  private user$: Observable<User | undefined> = this._store.select(getUser);
  // Current user
  private _user?: User;

  constructor(
    private _store: Store,
    private gaService: GoogleAnalyticsService,
    private _apiService: ApiService
  ) {
    // Subscribe to the NGRX user store for changes,
    // ensuring we run 'loadProtectedAttributes' on User initialisation only
    this.user$.pipe(pairwise()).subscribe(([prev, next ]) => {
      const user = next;
      if (user) {
        this._user = user;
        localStorage.setItem('user', JSON.stringify(this.sanitiseUser(user)));
        Sentry.configureScope((scope) => {
          scope.setTag('user-uuid', user.uuid);
          scope.setTag('user-display-name', user.displayName);
        });
        // Add UUID to GA Tracking
        this.gaService.gtag('config', environment.ga_tracking_id, {
          'user_id': user.uuid,
        });
        if (!prev) {
          this.loadProtectAttributes();
        }
      }
    });
  }

  save(user: User): void {
    // Save in state.
    this._store.dispatch(AccountActions.loadAccountSuccess({user}));
  }



  update(user: User): void {
    this._store.dispatch(AccountActions.updateAccount({user}));
    localStorage.setItem('user', JSON.stringify(this.sanitiseUser(user)));
  }

  getToken(): string {
    return this._user?.['accessToken'] ?? '';
  }

  getUser(): User | undefined {
    return this._user;
  }

  getUserObserv() : Observable<User | undefined> {
    return this.user$;
  }

  subscribeToChanges(callback: (user: User) => void)
  {
    return this.user$.subscribe(user => user && callback(user));
  }

  // Specialized version of above for use when a single value will do.
  subscribeToChangesOnce(callback: (user: User) => void)
  {
    return this.user$.pipe(take(1))
      .subscribe(user => user && callback(user));
  }

  loadFromLocalStorage(): void {
    if (localStorage.getItem('user')) {
      // Load user and cache.
      const user = JSON.parse(<string>localStorage.getItem('user'));
      this._store.dispatch(AccountActions.loadAccountSuccess({user}));
    }
  }

  clearUserData() {
    delete (this._user);
    localStorage.removeItem('user');
    //this._store.dispatch(AppActions.logout());
  }

  sanitiseUser(user: User) {
    // Remove sensitive attributes
    const { roles, ...sanitisedUser } = user;
    return sanitisedUser;
  }

  loadProtectAttributes(): void {
    const user = this.getUser();
    if (user && user['accessToken']) {
      this._apiService.getUserProtectedAttributes(user['accessToken'])
        .then(protectedAttributes => {
          const updatedUser = { ...user, ...protectedAttributes };
          this.update(updatedUser);
        })
        .catch(err => console.error(err));
    }
  }

  getUserOffCamStatusFromLocalStorage(): boolean {
    return localStorage.getItem(environment.local_storage_keys.off_cam) === 'true';
  }

  getUserDoNotDisturbStatusFromLocalStorage(): boolean {
    return localStorage.getItem(environment.local_storage_keys.do_not_disturb) === 'true';
  }

  updateUserCachedData(user: User): void {
    if (user && user['accessToken']) {
      this._apiService.updateUserCachedData(user['accessToken'], user)
        .then(data => {
          this.update(user);
          console.log(data.message);
        })
        .catch(err => console.error(err));
    }
  }
}
