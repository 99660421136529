import { Injectable } from '@angular/core';
import { Store } from "@ngrx/store";
import * as SettingsActions from '../../store/settings/settings.actions';
import { getSettings } from "../../store/settings/settings.selectors";
import { ApiService } from "@gatho/services/api/api.service";
import { filter, lastValueFrom, shareReplay } from 'rxjs';
import { SiteConfigDTO } from '@shared/models';

@Injectable({
  providedIn: 'root'
})

export class SettingsService
{
  public settings$ = this._store.select(getSettings).pipe(
    filter(Boolean),
    shareReplay(1),
  );

  constructor(
    private _store: Store,
    private _api: ApiService
  ) {
    this.load();
  }

  private async load()
  {
    const result = await lastValueFrom(this._api.getAppSettingsRequest());
    // TODO fill out all required properties?
    const settings = (result.package?.data ?? {}) as SiteConfigDTO;
    this._store.dispatch(SettingsActions.load({ settings }));
  }

  async getAppSettings()
  {
    return lastValueFrom(this.settings$);
  }

  async refresh()
  {
    const result = await lastValueFrom(this._api.getAppSettingsRequest());
    const settings = (result.package?.data ?? {}) as SiteConfigDTO;
    this._store.dispatch(SettingsActions.update({ settings }));
  }
}
