import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApiService } from "../../services/api/api.service";
import { UserService } from "../user/user.service";

@Injectable()
export class ExternalAuthGuardService implements CanActivate {

  constructor(
    private _userService: UserService,
    private _apiService : ApiService,
    private _router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot)
  {
    // Logged in users cant be here
    if (this._userService.getUser()) {
      return this._router.parseUrl('/');
    }
    const params = route.queryParams;
    // Hide somewhat sensitive data from the query params
    // its not really the biggest deal if it remains but isn't a good look
    // @TODO: authguard system returns these after the canactivate is succesfull
    //window.history.replaceState({}, '', window.location.origin + window.location.pathname);
    if (params['external']) {
      try {
        const user = await this._apiService.login(params['email'], params['pass']);
        this._userService.save(user);
        // needed?
        //this.checkAccess(user['accessToken']);
        return this._router.parseUrl('/');
      }
      catch (err) {
        this._userService.clearUserData();
        // on external failures send to login
        return this._router.parseUrl('/login');
      };
    }
    // Not external auth?
    return true;
  }

  checkAccess(accessToken: string) {
    // middleware will boot this user if not valid.
      this._apiService.checkAccess(accessToken)
        .catch(err => console.error(err));
  }
}
