import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { UserService } from "../user/user.service";
import { ApiService } from "../../services/api/api.service";
import { SnackBarService } from "../snackbar/snackbar.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  public base_url: string = window.location.origin;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _snackBar: SnackBarService,
    private _ApiService : ApiService
    ) {
    const user  = this._userService.getUser();


    _router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (user) {
          if (user['accessToken']) {
             this.checkAccess(user['accessToken']);
          }
          else {
            this._userService.clearUserData();

            //this._router.navigate(['/logout']);
            window.location.href = this.base_url +  '/logout';

          }
        }
      }
    });
  }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this._userService.getUser();

    // Capture the originally requested URL into localStorage
    const loggedIn = !!user;
    if (next && !loggedIn) {
      localStorage.setItem('login_dest_url', state.url);
    }

    if (!user) {
      if (state.url === '/') {
        this._router.navigate(['/gatho']);    //GATHO-495
      }
      else {
        // this._router.navigate(['/verify-age']);  //GATHO-495
        this._router.navigate(['/login']);    //GATHO-495
      }
      return false;
    }
    // Set blocked message.
    if (user && user.blocked) {
      this._snackBar.open('Your account is currently blocked, please email support@gatho.app if you wish to be unblocked.');
      //this._router.navigate(['/login']);
      window.location.href = this.base_url +  '/logout';
      return false;
    }

    if (user && user.phone && !user.otpVerified) {
      this._userService.clearUserData();
      this._snackBar.open('Please verify your account.');
      this._router.navigate(['/sign-up/ota']);
      return false;
    }
    return true;
  }


  public checkAccess(accessToken: string) {
    // middleware will boot this user if not valid.
      this._ApiService.checkAccess(accessToken)
        .catch(err => console.error(err));
  }

}
