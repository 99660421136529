import { Injectable, Injector } from '@angular/core';
import { environment } from "../../../environments/environment";
import { EndpointsService } from "./endpoints.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, lastValueFrom, throwError } from "rxjs";
import { User } from "@shared/models/User";
import { UserRegisterDto } from "../../store/models/userRegisterDto";
import { SiteConfigDTO } from "@shared/models/SiteConfigDTO";
import { Area } from "@shared/models/Area";
import { FrontPageArea } from "@shared/models/FrontPageArea";
import { BrandingSettings } from '@shared/models';
import { SettingsService } from './settings.service';
import { ExternalAuthPlatforms } from '@shared/models';

type ApiResponse<R extends Record<string, any> = Record<string, any>> = {
	status: boolean;
	message: string;
	package?: R;
	payload?: R;
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private get apiUrl() {
    return environment.api_url;
  }

  constructor(private endpoints: EndpointsService,
              private httpClient: HttpClient,
              private injector: Injector) {}

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was:`, error.error);
    }

    if (error.status === 401) {
      console.log(error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => 'Something went wrong; please try again later.')
  }

  private doPost<T = unknown>(endpoint: string, payload?: unknown) {
    return lastValueFrom(this.httpClient.post<T>(`${this.apiUrl}/${endpoint}`, payload).pipe(
      catchError(this.handleError),
    ));
  }

  private doGet<T = unknown>(endpoint: string) {
    return lastValueFrom(this.httpClient.get<T>(`${this.apiUrl}/${endpoint}`).pipe(
      catchError(this.handleError),
    ));
  }

  public login(email: String, password: String) {
    const { login } = this.endpoints;
    const isOffCam = localStorage.getItem(environment.local_storage_keys.off_cam) === 'true';
    const doNotDisturb = localStorage.getItem(environment.local_storage_keys.do_not_disturb) === 'true';
    return this.doPost<User>(login, {
      email,
      password,
      isOffCam,
      doNotDisturb
    });
  }

  public register(user: UserRegisterDto) {
    const { register } = this.endpoints;
    return this.doPost<User>(register, user);
  }

  public externalAuth(platform: ExternalAuthPlatforms) {
    const { externalAuth } = this.endpoints;
    return this.doGet(externalAuth[platform]);
  }

  public async getAppSettings() {
    // Avoids Circular Dependency
    return this.injector.get(SettingsService).getAppSettings();
  }

  public getAppSettingsRequest() {
    const { settings } = this.endpoints;
    return this.httpClient
      .get<ApiResponse<{ data: SiteConfigDTO }>>(`${this.apiUrl}/${settings}?clean=true`)
      .pipe(catchError(this.handleError));
  }

  public async getAreas() {
    const { areasEndpoint } = this.endpoints;
    const response = await this.doGet<ApiResponse<Area[]>>(`${areasEndpoint}?clean=true`);
    if (response.status && response.package) {
      return response.package;
    }
    return [];
  }

  public async getFrontPageContent() {
    const { frontPageAreas } = this.endpoints;
    const response = await this.doGet<ApiResponse<{ data: FrontPageArea[]; }>>(frontPageAreas);
    if (response.status && response.package) {
      return response.package.data;
    }
    return [];
  }

  public async getBrandingSettings() {
    const { brandingSettings } = this.endpoints;
    const response = await this.doGet<ApiResponse<{ data: BrandingSettings; }>>(brandingSettings);
    if (response.status && response.package) {
      return response.package.data;
    }
    return {uuid: 'branding'};
  }

  public forgotPassword(mail: string) {
    const { forgotpassword } = this.endpoints;
    return this.doPost<ApiResponse>(forgotpassword, { mail });
  }

  /**
   * Reset password.
   */
  public resetPassword(name: string, temp_pass: string, new_pass: string) {
    const { resetpassword } = this.endpoints;
    return this.doPost<ApiResponse>(resetpassword, {
      name,
      temp_pass,
      new_pass
    });
  }

  /**
   * ota sms code check
   */
  public otaSmsCodeCheck(mail: string, ota_code: string) {
    const { otaSmsCode } = this.endpoints;
    return this.doPost<ApiResponse>(otaSmsCode, {
      mail,
      ota_code
    });
  }

  public resendOtpCode(mail_or_phone: string) {
    const { otaSmsEmailCodeResend } = this.endpoints;
    return this.doPost<ApiResponse>(otaSmsEmailCodeResend, {
      mail_or_phone
    });
  }

  /**
   * Update user profile Picture
   */
  public updateUserProfilePicture(_accessToken: string, base_64_image : string, file_name : string) {
    const { profileImageUpdate } = this.endpoints;
    return this.doPost<ApiResponse>(profileImageUpdate, {
      //accessToken,
      base_64_image,
      file_name
    });
  }

  /**
   * Update user cached data
   */
  public updateUserCachedData(_accessToken: string, user: User) {
    const { updateUserCachedData } = this.endpoints;
    return this.doPost<ApiResponse>(updateUserCachedData, {
      user
    });
  }

  /**
   * Update user profile.
   */
  public updateUserProfile(_accessToken: string, user: User) {
    const { update_user_profile } = this.endpoints;
    // Split out non-update able fields
    const {
      accessToken,
      profilePicture,
      profilePictureSmall,
      ...updateFields
    } = user;
    return this.doPost<ApiResponse>(update_user_profile, {
      //accessToken,
      user: updateFields
    });
  }

  /**
   * reports a user.
   * @param accessToken
   * @param blockUserUUID
   * @param type
   * @param reason
   */
  public reportUser(_accessToken: string, blockUserUUID: string, type: string, reason: string) {
    const { report_user } = this.endpoints;
    return this.doPost<ApiResponse>(report_user, {
      blockUserUUID,
      type,
      reason
    });
  }


  public checkEmailForImportedUser(email: string) {
    const { imported_user_check } = this.endpoints;
    // see gatho_users/src/Controller/ImportedCheckController.php for return type
    return this.doPost<ApiResponse<{ result: "yes" | "no"; }>>(imported_user_check, {
      email
    });
  }

  /**
   * Check the user token is still good.
   * @param accessToken
   */
  public checkAccess(_accessToken: string) {
    const { checkAccess } = this.endpoints;
    return this.doPost<ApiResponse>(checkAccess);
  }


  public checkAreaTicketApiCall(AreaUUID: string, UserUUID: string) {
    const {checkAreaTicket} = this.endpoints;
    return this.doPost<ApiResponse<{ message: 'yes' | 'no' }>>(checkAreaTicket, {
      AreaUUID,
      UserUUID
    });
  }


  public checkAreaForOrderAndUserCall(AreaUUID: string, UserUUID: string, orderNumber: string) {
    const {checkAreaForOrderAndUser} = this.endpoints;
    return this.doPost<ApiResponse>(checkAreaForOrderAndUser, {
      AreaUUID,
      UserUUID,
      orderNumber
    });
  }

  public async getUserProtectedAttributes(_accessToken: string) {
    const { getUserProtectedAttributes } = this.endpoints;
    const response = await this.doPost<ApiResponse<{ roles: string[]; }>>(getUserProtectedAttributes);
    if (response.status) {
      return response.package;
    }
    return;
  }
}
