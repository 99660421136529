import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { Router } from '@angular/router';
import {
  createErrorHandler as sentryCreateErrorHandler,
  TraceService as SentryTraceService,
} from '@sentry/angular';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuardService as AuthGuard } from './services/guards/auth-guard.service';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

// Material Angular components
import { MatButtonModule } from '@angular/material/button';
import {
  MatNativeDateModule,
  MatRippleModule,
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { enAU } from 'date-fns/locale';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/effects/app.effects';
import { HttpInterceptorService } from './services/interceptors/http-interceptor.service';
import { AuthInterceptor } from './services/interceptors/auth-interceptor.service';

import { environment } from '../environments/environment';
import { VerifyAgeComponent } from './components/pages/verify-age/verify-age.component';
import { LoginComponent } from './components/pages/login/login.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { RouteListComponent } from './route-list/route-list.component';
import { TermsComponent } from './components/disclaimers/terms/terms.component';
import { PrivacyPolicyComponent } from './components/disclaimers/privacy-policy/privacy-policy.component';
import { ContributorRulesComponent } from './components/disclaimers/contributor-rules/contributor-rules.component';
import { ReturnsPolicyComponent } from './components/disclaimers/returns-policy/returns-policy.component';
import { ForgotPasswordComponent } from './components/pages/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { OtaMobileComponent } from './components/pages/sign-up/ota-mobile/ota-mobile.component';
import { LogoutComponent } from './components/pages/logout/logout.component';
import { MoveNextMaxLengthDirective } from './directives/move-next-max-length.directive';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { ResendOtaComponent } from './components/pages/sign-up/resend-ota/resend-ota.component';
import { DisclaimerPagesComponent } from './components/disclaimers/pages/disclaimer-pages/disclaimer-pages.component';
import { MarketingNavComponent } from './components/marketing-nav/marketing-nav.component';
import { BecomeGathoVenueComponent } from './components/pages/become-gatho-venue/become-gatho-venue.component';
import { MainLandingComponent } from './components/pages/main-landing/main-landing.component';
import { LogoSectionComponent } from './components/logo-section/logo-section.component';
import { ContentSectionComponent } from './components/content-section/content-section.component';
import { MatMenuModule } from "@angular/material/menu";
import { NbThemeModule, NbLayoutModule, NbWindowModule } from '@nebular/theme';
import { ExternalAuthGuardService } from './services/guards/external-auth-guard.service';


const config: SocketIoConfig = {
  get url() { return environment.socket_server },
  options: { withCredentials: true },
};

const appDateFormat = {
  parse: {
    dateInput: 'dd/MM/yyyy',
  },
  display: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'LLL y',
    dateA11yLabel: 'MMMM d, y',
    monthYearA11yLabel: 'MMMM y',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    VerifyAgeComponent,
    SignUpComponent,
    RouteListComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    ContributorRulesComponent,
    ReturnsPolicyComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    OtaMobileComponent,
    LogoutComponent,
    MoveNextMaxLengthDirective,
    PageNotFoundComponent,
    ResendOtaComponent,
    DisclaimerPagesComponent,
    BecomeGathoVenueComponent,
    MarketingNavComponent,
    MainLandingComponent,
    LogoSectionComponent,
    ContentSectionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    SocketIoModule.forRoot(config),
    BrowserModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga_tracking_id),
    NgxGoogleAnalyticsRouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([AppEffects]),
    MatBottomSheetModule,
    NgxMatIntlTelInputComponent,
    MatMenuModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbWindowModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: sentryCreateErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: SentryTraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        /* TODO document why this arrow function is empty */
      },
      deps: [SentryTraceService],
      multi: true,
    },
    AuthGuard,
    ExternalAuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: appDateFormat },
  ],
  entryComponents: [
    TermsComponent,
    PrivacyPolicyComponent,
    ReturnsPolicyComponent,
    ContributorRulesComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
