import { createReducer, on } from '@ngrx/store';
import * as SettingsActions from './settings.actions';
import { SiteConfigDTO } from '@shared/models';

export const settingsFeatureKey = 'settings';

export interface SettingsState {
  loaded: boolean;
  settings?: SiteConfigDTO;
}

export const initialState: SettingsState = {
  loaded: false
};

export const settingsReducer = createReducer(
  initialState,
  on(SettingsActions.load, (state, action) => {
    return {
      ...state,
      loaded: true,
      settings: action.settings
    }
  }),
  on(SettingsActions.update, (state, action) => {
    return {
      ...state,
      settings: action.settings
    }
  })
);
