import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SnackBarService } from "../snackbar/snackbar.service";
import { UserService } from "../user/user.service";
import { Router } from "@angular/router";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private _snackBar: SnackBarService,
              private _userService: UserService,
              private _router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        let message = error.message;
        const code = error.status;
        if(!message){
          message = 'Oops, something went wrong...';
        }

        if(code === 401) {
          // This is 95% of the time used when the user auth token has expired ect.
          console.log('url', this._router.url);

          if (this._router.url === '/sign-up/ota') {
            return throwError(() => error);
          }

          this._userService.clearUserData();
          if (this._router.url !== "/login"){
            message = "Access denied, please log in.";
            this._snackBar.open(message, {panelClass: 'error'});
            this._userService.clearUserData();
            window.location.href = window.location.origin +  '/logout';
            //this._router.navigate(['/login']);
          }
        }
        return throwError(() => error);
      })
    );
  }
}
