import { Component, ElementRef } from '@angular/core';
import { UserService } from './services/user/user.service';
import { BrandingService } from './services/branding/BrandingService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'gatho';

  constructor(
    private _userService: UserService,
    private _elementRef: ElementRef,
    private _branding: BrandingService
  ) {
    _userService.loadFromLocalStorage();
    this.setBranding();
  }

  // Apply branding config that isn't specific to sub-components (e.g: buttons)
  setBranding() {
    //Primary Color
    this._branding.setCssProperty(this._elementRef, '--primary-color', 'primaryColor');

    //Primary Button
    this._branding.setCssProperty(this._elementRef, '--button-primary-text-color', 'primaryButtonTextColor');
    this._branding.setCssProperty(this._elementRef, '--button-primary-start-color', 'primaryButtonStartColor');
    this._branding.setCssProperty(this._elementRef, '--button-primary-end-color', 'primaryButtonEndColor');
    //Primary Button Hover
    this._branding.setCssProperty(this._elementRef, '--button-primary-text-color-hover', 'primaryButtonTextColorHover');
    this._branding.setCssProperty(this._elementRef, '--button-primary-start-color-hover', 'primaryButtonStartColorHover');
    this._branding.setCssProperty(this._elementRef, '--button-primary-end-color-hover', 'primaryButtonEndColorHover');
    //Primary Button Focus
    this._branding.setCssProperty(this._elementRef, '--button-primary-text-color-focus', 'primaryButtonTextColorFocus');
    this._branding.setCssProperty(this._elementRef, '--button-primary-start-color-focus', 'primaryButtonStartColorFocus');
    this._branding.setCssProperty(this._elementRef, '--button-primary-end-color-focus', 'primaryButtonEndColorFocus');
    //Primary Button Active
    this._branding.setCssProperty(this._elementRef, '--button-primary-text-color-active', 'primaryButtonTextColorActive');
    this._branding.setCssProperty(this._elementRef, '--button-primary-start-color-active', 'primaryButtonStartColorActive');
    this._branding.setCssProperty(this._elementRef, '--button-primary-end-color-active', 'primaryButtonEndColorActive');

    //Secondary Button
    this._branding.setCssProperty(this._elementRef, '--button-secondary-text-color', 'secondaryButtonTextColor');
    this._branding.setCssProperty(this._elementRef, '--button-secondary-bg-color', 'secondaryButtonBgColor');
    //Secondary Button Hover
    this._branding.setCssProperty(this._elementRef, '--button-secondary-text-color-hover', 'secondaryButtonTextColorHover');
    this._branding.setCssProperty(this._elementRef, '--button-secondary-bg-color-hover', 'secondaryButtonBgColorHover');
    //Secondary Button Focus
    this._branding.setCssProperty(this._elementRef, '--button-secondary-text-color-focus', 'secondaryButtonTextColorFocus');
    this._branding.setCssProperty(this._elementRef, '--button-secondary-bg-color-focus', 'secondaryButtonBgColorFocus');
    //Secondary Button Active
    this._branding.setCssProperty(this._elementRef, '--button-secondary-text-color-active', 'secondaryButtonTextColorActive');
    this._branding.setCssProperty(this._elementRef, '--button-secondary-bg-color-active', 'secondaryButtonBgColorActive');

    //Icon Button
    this._branding.setCssProperty(this._elementRef, '--button-icon-color', 'iconButtonIconColor');
    this._branding.setCssProperty(this._elementRef, '--button-icon-bg-start-color', 'iconButtonBgStartColor');
    this._branding.setCssProperty(this._elementRef, '--button-icon-bg-end-color', 'iconButtonBgEndColor');
  }

  ngOnInit(): void {
    this.safariAddressBarState();
  }

  isIOSDevice() {
    // return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }

  safariAddressBarState() {
    if (this.isIOSDevice()) {
      // Hide address bar
      window.addEventListener('load', function () {
        setTimeout(function () {
          window.scrollTo(0, 1);
        }, 1000);
      });
    }
  }
}
