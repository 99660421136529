export const environment = {
  name: 'dev',
  production: true,
  socket_server: 'https://live-dev-gatho.atech.host',
  api_url: 'https://live-dev-gatho.atech.host/api',
  admin_url: 'https://admin-dev-gatho.atech.host/',
  ga_tracking_id: 'G-Q7L2KWNTVJ', // 'Gatho DEV' property
  local_storage_keys: {
    off_cam: 'off_cam',
    do_not_disturb: 'do_not_disturb',
    muted: 'muted'
  },
  // Use the 'live' red5pro instance for now
  red5ProDevSubscriber: {
    protocol: 'wss',
    host: 'media-dev.gatho.app',
    port: 443,
    app: 'live',
    streamName: 'venuestream',
    connectionParams: {
      username: 'na',
      password: 'na',
      // @TODO: get token based on user session
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzA2LCJyb2xlIjpbeyJyb2xlIjoidXNlciIsImlkIjoxfV0sImlhdCI6MTY2MDA4OTgxMCwiZXhwIjoxNjYwNjk0NjEwfQ.WNcNWnxTyPDuwQdBZRUOlb-zAYBFY_bBHXLZTWKraa4',
    }
  },
  red5ProProdSubscriber: {
    protocol: 'wss',
    host: 'media.gatho.app',
    port: 5443,
    app: 'live',
    streamName: 'venuestream',
    connectionParams: {
      username: 'na',
      password: 'na',
      // @TODO: get token based on user session
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzA2LCJyb2xlIjpbeyJyb2xlIjoidXNlciIsImlkIjoxfV0sImlhdCI6MTY2MDA4OTgxMCwiZXhwIjoxNjYwNjk0NjEwfQ.WNcNWnxTyPDuwQdBZRUOlb-zAYBFY_bBHXLZTWKraa4',
    }
  },
  red5ProProdPublisher: {
    protocol: 'wss',
    host: 'media.gatho.app',
    port: 5443,
    app: 'live',
    streamName: '',
    mediaElementId: 'self-video-media',
    connectionParams: {
      username: 'na',
      password: 'na',
      // @TODO: get token based on user session
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzA2LCJyb2xlIjpbeyJyb2xlIjoidXNlciIsImlkIjoxfV0sImlhdCI6MTY2MDA4OTgxMCwiZXhwIjoxNjYwNjk0NjEwfQ.WNcNWnxTyPDuwQdBZRUOlb-zAYBFY_bBHXLZTWKraa4',
    }
  }
};
